import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import { STEPS, StepKeys } from 'pages/CreateUniProfilePage/data';
import { StepValue } from 'pages/UniprofileResume/types';
import { EStep } from 'pages/CreateUniProfilePage/types';
import { Button } from 'antd';

interface IProps {
    uniprofileSubmitted?: boolean;
    isAva?: boolean,
    steps: Array<{
        title: string,
        desc: string,
        step: EStep,
        hide?: boolean,
    }>,
    percentages?: Record<string, {
        percent: number;
        unfinished: []
    }>,
    currentStep?: number,
    onStepClick?: (index: number) => void;
    onClickNext?: () => void;
}

const SideStep = ({
    uniprofileSubmitted,
    isAva,
    steps,
    percentages,
    currentStep = 0,
    onStepClick,
    onClickNext,
}: IProps) => {
    // background: conic-gradient(#00816B 45deg, #0000 45deg);
    const getIconClass = (step: EStep) => {
        const isFinished =!!uniprofileSubmitted || currentStep > step;
        const isCurrent = currentStep === step;

        if (isCurrent) {
            return `${s.stepItem} ${s.current}`;
        } else if (isAva) {
            const stepName = StepKeys[step];
            const percent = percentages?.[stepName]?.percent || 0;

            // 非数字，且没有记录percent，那么就算是完成的。
            // 或者是100%
            if ((!stepName && step !== EStep.PREVIEW) || percent === 1) {
                return `${s.stepItem} ${s.finished}`;
            }

            // 数字，但没有100%
            if (typeof percent === 'number' && percent !== 1) {
                return `${s.stepItem} ${s.inProgress}`;
            }
        } else if (isFinished) {
            return `${s.stepItem} ${s.finished}`;
        }

        return s.stepItem;
    };

    // const getPercent = useCallback((index: number) => {
    //     if (percentages) {
    //         const stepName = StepKeys[index];
    //         const percent = percentages[stepName];
    //         if (isAva && percent !== 100) {
    //             const per = 180;
    //             return {
    //                 background: `conic-gradient(#00816B ${per}deg, #0000 ${per}deg)`,
    //             };
    //         }
    //     }

    //     return {};
    // }, [isAva, percentages]);

    const handleStepClick = useCallback((step: number) => {
        onStepClick?.(step);
    }, [onStepClick]);

    const renderNextButton = useCallback((step: EStep) => {
        const buttonText = step === EStep.PREVIEW ? 'Submit for review' : 'Next';
        let buttonProps = {
            type: 'primary',
            htmlType: 'submit',
            form: STEPS[currentStep],
        };

        if (step === EStep.PREVIEW) {
            buttonProps = {
                // loading,
                type: 'primary',
                onClick: () => onClickNext?.(),
            };
        }

        // 内部有额外编辑表单的
        if (step === EStep.SERVICES || step === EStep.PRACTICE || step === EStep.ADDITIONAL_INFORMATION) {
            buttonProps = {
                type: 'primary',
                onClick: onClickNext,
            };
        }

        return <Button className={s.hiddenSubmitBtn} {...buttonProps}>{buttonText}</Button>;
    }, [currentStep, onClickNext]);

    return (
        <div className={s.wrapper}>
            {
                steps.map((step, index) => {
                    const { title, desc, hide } = step;

                    // const buttonProps = {
                    //     type: 'primary',
                    //     htmlType: 'submit',
                    //     form: STEPS[currentStep],
                    // };

                    return hide ? null : (
                        <div key={index} className={getIconClass(step.step)} onClick={() => handleStepClick(index)}>
                            {/* <Button className={s.hiddenSubmitBtn} {...buttonProps}>333333</Button> */}
                            {/* { renderNextButton(currentStep) } */}
                            <div className={s.stepIcon}>
                                <div className={s.icon} />
                            </div>
                            <div className={s.stepInfo}>
                                <div className={s.name}>{title}</div>
                                <div className={s.desc}>{desc}</div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default SideStep;
