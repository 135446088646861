import React, { useEffect, useMemo, useRef, useState } from "react";
import s from './s.module.less';
// import { Popover, Tooltip } from "antd";
import { EServiceType, ServerMeta, TKlarityStateTPrice } from "types/common";
import { getKlarityPriceLevel } from 'utils/service';
import Icon1 from 'components/ApplicationFormComponents/KlarityServices/components/ServiceModal/components/ConfigService/components/ConfigServiceType/images/mood1.svg';
import Icon2 from 'components/ApplicationFormComponents/KlarityServices/components/ServiceModal/components/ConfigService/components/ConfigServiceType/images/mood2.svg';
import Icon3 from 'components/ApplicationFormComponents/KlarityServices/components/ServiceModal/components/ConfigService/components/ConfigServiceType/images/mood3.svg';
import Icon4 from 'components/ApplicationFormComponents/KlarityServices/components/ServiceModal/components/ConfigService/components/ConfigServiceType/images/mood4.svg';
import Icon5 from 'components/ApplicationFormComponents/KlarityServices/components/ServiceModal/components/ConfigService/components/ConfigServiceType/images/mood5.svg';
import { Input, InputNumber } from "antd";


interface IProps {
    forceUpdate: Record<any, any>,
    stateContentDurationMap: Record<string, number>,
    consultationOverPrice: number;
    isConsultation: boolean;
    stateContentMyEaringMap: Record<string, number>,
    item: ServerMeta;
    serviceType: EServiceType,
    allStateConfigTypePriceData?: Record<string, TKlarityStateTPrice>,
    onChange?: (value: number, priceLevel: number, bgColor: string) => void;
    setStateContentMyEaringMap: (value: Record<string, number>) => void;
}

const Slider = ({
    forceUpdate,
    stateContentDurationMap,
    onChange,
    consultationOverPrice,
    isConsultation,
    item,
    allStateConfigTypePriceData,
    stateContentMyEaringMap,
    serviceType,
    setStateContentMyEaringMap
}: IProps) => {
    const sliderTrackerEl = useRef(null);
    const [val, setVal] = useState(20); // 默认值
    const sliderRef = useRef(null);
    const maxValue = 1000;
    // const [tipClass, setTipClass] = useState('');
    const [tipText, setTipText] = useState('');
    const [tipImage, setTipImage] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [gradient, setGradient] = useState('');
    const [priceLevel, setPriceLevel] = useState(0);
    const [isMoving, setIsMoving] = useState(false);
    const firstPecent = 0.25;
    const secondPecent = 0.5;
    const thirdPecent = 0.75;
    const fourthPecent = 1;

    useEffect(() => {
        const earning = stateContentMyEaringMap[item.content];
            setVal(earning);
            // setMaxValue(value + 100);
    }, [stateContentMyEaringMap, item]);

    const getColorFromGradient = (percentage?: number) => {
        const width = sliderTrackerEl.current.offsetWidth;
        const canvas = document.getElementById('canvas');
        canvas.width = width;
        canvas.style.width = width + 'px';
        const ctx = canvas.getContext('2d');
        
        // Create a gradient
        const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
        gradient.addColorStop(0, "#BAE6FD");
        gradient.addColorStop(firstPecent, "#87DBCC");
        gradient.addColorStop(secondPecent, "#FDE68A");
        gradient.addColorStop(thirdPecent, "#FED7AA");
        gradient.addColorStop(fourthPecent, "#FCA5A5");
        
        // Apply the gradient to the canvas
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    
        if (percentage) {
            // Get the pixel data from the canvas at the specified percentage
            const x = Math.floor(percentage * canvas.width);
            const pixel = ctx.getImageData(x, 1, 1, 1).data;
        
            // Return the RGB color in a CSS-friendly format
            return `rgb(${pixel[0]}, ${pixel[1]}, ${pixel[2]})`;
        }
      }

      const getPriceInfo = () => {
        const statePrice = allStateConfigTypePriceData?.[item.content];

        let defaultVal = statePrice?.[EServiceType.INIT]?.krc;
        let mmuPrice = statePrice?.[EServiceType.INIT]?.mmu;
        let pfPrice = statePrice?.[EServiceType.INIT]?.pf;
        let recommendPriceArea = statePrice?.[EServiceType.INIT]?.recommendPriceArea;
        if (serviceType === EServiceType.CONSULTATION) {
            defaultVal = statePrice?.[EServiceType.CONSULTATION]?.krc;
            mmuPrice = statePrice?.[EServiceType.CONSULTATION]?.mmu;
            pfPrice = statePrice?.[EServiceType.CONSULTATION]?.pf;
            recommendPriceArea = statePrice?.[EServiceType.CONSULTATION]?.recommendPriceArea;

            const earning = stateContentMyEaringMap[item.content];
            if (earning && isConsultation && earning > consultationOverPrice && pfPrice === 0) {
                pfPrice = statePrice?.[EServiceType.INIT]?.pf;
                recommendPriceArea = statePrice?.[EServiceType.CONSULTATION_OVERCHARGE]?.recommendPriceArea;
            }
        }
        if (serviceType === EServiceType.ESTABLISHED_PATIENT_VISIT) {
            defaultVal = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.krc;
            mmuPrice = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.mmu;
            pfPrice = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.pf;
            recommendPriceArea = statePrice?.[EServiceType.ESTABLISHED_PATIENT_VISIT]?.recommendPriceArea;
        }
        if (serviceType === EServiceType.MEDICATION_REFILL) {
            defaultVal = statePrice?.[EServiceType.MEDICATION_REFILL]?.krc;
            mmuPrice = statePrice?.[EServiceType.MEDICATION_REFILL]?.mmu;
            pfPrice = statePrice?.[EServiceType.MEDICATION_REFILL]?.pf;
            recommendPriceArea = statePrice?.[EServiceType.MEDICATION_REFILL]?.recommendPriceArea;
        }
        if (serviceType === EServiceType.OTHER_PATIENT_SUPPORT) {
            defaultVal = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.krc;
            mmuPrice = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.mmu;
            pfPrice = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.pf;
            recommendPriceArea = statePrice?.[EServiceType.OTHER_PATIENT_SUPPORT]?.recommendPriceArea;
        }

        return {
            defaultVal,
            mmuPrice,
            pfPrice,
            recommendPriceArea,
        };
      }

    //   const initValue = useMemo(() => stateContentMyEaringMap[item.content], [])

      const init = () => {
        const { defaultVal, mmuPrice, pfPrice, recommendPriceArea } = getPriceInfo();

        const totalPrice = val + mmuPrice! + pfPrice!;
        const maxEarnValue = maxValue - mmuPrice! - pfPrice!;
        const recommandMaxPrice = recommendPriceArea ? Math.max(maxEarnValue, recommendPriceArea[3]) : maxValue;
        // 有时候totalPrice 远超 推荐价格的最高价，导致滑块超出了范围，所以以大的那个数字作为滑块的最大限制值
        // const maxPrice = Math.max(totalPrice, recommandMaxPrice) + 200
        // setMaxValue(maxPrice);
        let recommendPrices = [0,Math.round(maxValue/4),Math.round(maxValue/2),Math.round(maxValue/4*3), maxValue];

        if (recommendPriceArea) {
            recommendPriceArea.splice(4, 1, recommandMaxPrice);
            recommendPrices = recommendPriceArea;
        }
        // const recommendPrices = recommendPriceArea ? recommendPriceArea.splice(3, 1, recommandMaxPrice) : [0,Math.round(maxValue/4),Math.round(maxValue/2),Math.round(maxValue/4*3), maxValue];

        const total = recommendPrices ? recommendPrices.reduce((sum, value) => sum + value, 0) : maxValue;
        const colors = ['#BAE6FD', '#87DBCC', '#FDE68A', '#FED7AA', '#FCA5A5'];

        let stopPoints: string[] = [];
        const percentArr: number[] = []
        let cumulativePercent = 0

        console.info(111, recommendPrices, recommendPriceArea)

        recommendPrices?.forEach((value, index) => {
            const p = value / total;
            const percent = p * 100;
            percentArr.push(p);
            cumulativePercent += percent;
            stopPoints.push(`${colors[index]} ${cumulativePercent}%`);
        });

        const gradient = `linear-gradient(90deg, ${stopPoints.join(', ')})`;

        // const color = `linear-gradient(90deg, #BAE6FD 0%, #87DBCC ${firstPecent* 100}%, #FDE68A ${secondPecent* 100}%, #FED7AA ${thirdPecent* 100}%, #FCA5A5 100%)`;
        // const color2 = `linear-gradient(90deg, #BAE6FD ${firstPecent* 100}%, #87DBCC ${secondPecent* 100}%, #FDE68A ${thirdPecent* 100}%, #FED7AA ${fourthPecent* 100}%, #FCA5A5 100%)`;
        // const color3 = `linear-gradient(90deg, #BAE6FD 0%, #87DBCC ${firstPecent* 100}%, #FDE68A ${secondPecent* 100}%, #FED7AA ${thirdPecent* 100}%, #FCA5A5 ${fourthPecent* 100}%)`;
        getColorFromGradient();

        setGradient(gradient);
      }
    

    useEffect(() => {
        init();
    }, []);

    const getAvaRecommendInfo = (v: number) => {
        const { mmuPrice, pfPrice, recommendPriceArea } = getPriceInfo();

        const total = v + mmuPrice! + pfPrice!;

        const [level, percent] = getKlarityPriceLevel(total, recommendPriceArea);

        let tipsClass = ''
        let tipText = '';
        let image = '';

        if (level === 1) {
            // tipsClass = `${s.tips} ${s.tipsLevel1}`;
            tipsClass = s.tipsLevel1;
            tipText = `$${total} is very competitive in your area`;
            image = Icon1;
        }
        if (level === 2) {
            // tipsClass = `${s.tips} ${s.tipsLevel2}`;
            tipsClass = s.tipsLevel2
            tipText = `$${total} is competitive in your area`;
            image = Icon2;
        }
        if (level === 3) {
            // tipsClass = `${s.tips} ${s.tipsLevel3}`;
            tipsClass = s.tipsLevel3
            tipText = `$${total} is average in your area`;
            image = Icon3;
        }
        if (level === 4) {
            // tipsClass = `${s.tips} ${s.tipsLevel4}`;
            tipsClass = s.tipsLevel4
            tipText = `$${total} is high in your area`;
            image = Icon4;
        }
        if (level === 5) {
            // tipsClass = `${s.tips} ${s.tipsLevel5}`;
            tipsClass = s.tipsLevel5
            tipText = `$${total} is absolutely not competitive`;
            image = Icon5;
        }

        // setTipClass(tipsClass);
        setTipText(tipText);
        setTipImage(image);
        setPriceLevel(level);

        const bgColor = getColorFromGradient(percent/100)
        setBackgroundColor(bgColor);
    };

    useEffect(() => {
        onChange?.(val, priceLevel, backgroundColor);
    }, [val, priceLevel, backgroundColor]);

    useEffect(() => {
        if (!isMoving) {
            init();
            setTimeout(() => {
                getAvaRecommendInfo(val); 
            }, 100);
        }
    }, [forceUpdate, val]);

    const syncValueToMyEaringMap = (value: number) => {
        const _stateContentMyEaringMap = { ...stateContentMyEaringMap };
        _stateContentMyEaringMap[item.content] = value;
        setStateContentMyEaringMap(_stateContentMyEaringMap);
    }
  
    const handleMouseDown = (e) => {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const sliderLeft = slider.getBoundingClientRect().left;
  
      const onMouseMove = (event) => {
        setIsMoving(true);
        const newLeft = event.clientX - sliderLeft;
        const newValue = Math.round(Math.min(Math.max((newLeft / sliderWidth) * maxValue, 0), maxValue)); // 限制值在0到100之间

        setVal(newValue);
        syncValueToMyEaringMap(newValue);
        getAvaRecommendInfo(newValue);
      };
  
      const onMouseUp = () => {
        setIsMoving(false);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
  
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    console.info(1111, gradient)
  
    return (
        <div className={s.wrap}>
            <div className={s.top}>
                <div className={s.title}>How much you will earn</div>
                <div className={s.earnInput}>$<InputNumber max={maxValue} value={val} onChange={(e) => {
                    const newValue = Number(e);
                    setVal(newValue)
                    syncValueToMyEaringMap(newValue);
                }}/></div>
            </div>
            <div className={s.body}></div>
            <div className={s.sliderWrap}>
            <canvas id="canvas" width={260} height="10" style={{display:"none", background: gradient}}></canvas>
            <div className={s.slider} ref={sliderRef} onMouseDown={handleMouseDown}>
            <div className={s.sliderTrack} ref={sliderTrackerEl} style={{background: gradient}}></div>
                <div className={s.sliderThumb} style={{ left: `${((val - 12)/maxValue)*100}%` }}>
                    { tipText && (
                        <div className={`${s.tooltip}`} style={{backgroundColor}}>
                            <div className={`${s.arrow}`} style={{borderTopColor: backgroundColor}}></div>
                            <div className={s.tiptext}><img src={tipImage} /> {tipText}</div>
                        </div>
                        )
                    }
                </div>
            </div>
        </div>
        </div>
    );
  };

export default Slider;
